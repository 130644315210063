import {createRef} from "preact";
import {useState} from "preact/hooks";

import {extent, line, max, min, scaleLinear} from "d3";

const size_inside_plot = 90;
const space_between_plots = 20;
const size = size_inside_plot + space_between_plots;

const padding_left = 120;
const padding_top = 30;

const padding_bottom = 40;
const padding_right = 100;


const models = [ "sf94_day5_prot", 'sf94_day5', 'sus_1', 'sus_2', 'who_day5'];
// full list is ["sf94_day5", "sf94_day8", "sus_1", "sus_2", "who_day5", "who_day8"];

const modelStyle = {
    "sf94_day5_prot": {color: '#1b9e77', fill: '#1b9e77', label: "S/F94 day 5 protocolised"},
    "sf94_day5": {color: '#1b9e77', fill: 'white', label: "S/F94 day 5"},
    "sus_1": {color: '#d95f02', fill: '#d95f02', label:  "1 level sustained improvement in 28 days"},
    "sus_2": {color: '#d95f02', fill: 'white', label:  "2 level sustained improvement in 28 days"},
    "who_day5": {color: '#7570b3', fill: '#7570b3', label: "WHO day 5"},
};


const ResultsPlot = ({results, mortalityRate}) => {

    const [highlightState, setHighlightState] = useState({power: 0.8, confidence: 0.95, treatment: 0.85});

    const allPowers = results.map(d => d.power);
    const powers = allPowers.filter((d, i) => allPowers.indexOf(d) === i);

    const allTreatments = results.map(d => d.treatment);
    const treatments = allTreatments.filter((d, i) => allTreatments.indexOf(d) === i);

    const allConfidence = results.map(d => d.confidence);
    const confidences = allConfidence.filter((d, i) => allConfidence.indexOf(d) === i);


    let overallMax = 0;
    for (let model of models) {
        const thisMax = max(results.map(r => +r[model]));
        overallMax = Math.max(overallMax, thisMax);
    }

    const svgWidth = size * powers.length + padding_left;
    const svgHeight = size * confidences.length + 20;

    const x = scaleLinear().range([0, size_inside_plot]).domain(extent(treatments));
    const y = scaleLinear().range([0, size_inside_plot]).domain([overallMax, 0]);

    return <>


        {!highlightState &&
        <p><b>Click on a plot to see the calculated sample sizes for a particular combination of power,
            confidence,
            and treatment effect</b></p>}
        {highlightState && <ResultList highlightState={highlightState} results={results} mortalityRate={mortalityRate} powers={powers} confidences={confidences} treatments={treatments} setHighlightState={setHighlightState}/>}

        <svg viewBox={`0 0 ${svgWidth + padding_right} ${svgHeight + padding_bottom}`}
             style={{"max-width": "100%", "max-height": "100%", "margin-left": "auto", "margin-right": "auto"}}>

            <text x={padding_left + (svgWidth - padding_left) / 2} y={10} style={{"text-anchor": "middle"}}>Power
            </text>
            <text x={10} y={padding_top + (svgHeight - padding_top) / 2 + 35}
                  style={{"text-anchor": "start"}}>Confidence
            </text>

            <g transform={`translate(${padding_left}, ${padding_top})`}>

                {powers.map((power, i) => <text x={size * (i + 0.5)} y={-4}
                                                style={{"text-anchor": "middle"}}>{power}</text>)}

                {confidences.map((confidence, i) => <text x={0} y={size * (i + 0.5)}
                                                          style={{"text-anchor": "end"}}>{confidence}</text>)}

                {confidences.map((confidence, i) => <g
                    transform={`translate(${svgWidth - padding_left - 10}, ${i * size})`}>
                    <line x1={0} x2={0} y1={0} y2={size_inside_plot} stroke="black"/>
                    <YAxis y={y}/>
                </g>)}

                {powers.map((power, i) => <g transform={`translate(${i * size}, ${svgHeight - padding_top})`}>
                    <line x1={0} x2={size_inside_plot} y1={0}
                          y2={0} stroke="black"/>
                    <XAxis x={x}/>
                </g>)}


                {powers.map((power, i) => {
                    return confidences.map((confidence, j) => {
                        return <g transform={`translate(${size * i}, ${size * j})`}>
                            <SubPlot data={results.filter(d => d.power === power && d.confidence === confidence)}
                                     x={x} y={y}
                                     power={power} confidence={confidence}
                                     models={models}
                                     highlightState={highlightState}
                                     setHighlightState={setHighlightState}
                            />
                        </g>
                    })
                })}
            </g>
        </svg>
    </>
}


const XAxis = ({x}) => {
    return <>
        {x.ticks(4).map(d => <g class="xAxis" transform={`translate(${x(d)}, 0)`}>
        <line x1={0} x2={0} y1={0} y2={10} stroke="black" />
        <text x={0} y={20} style={{textAnchor: "middle", "font-size": "10px"}}>{d}</text>
    </g>)}

    <text x={size_inside_plot/2} y={35} text-anchor={"middle"} font-size={"10px"}>Treatment Effect</text>

    </>
}

const YAxis = ({y}) => {
    return <>
        {y.ticks(4).map(d => <g class="yAxis" transform={`translate(0, ${y(d)})`}>
        <line x1={0} x2={10} y1={0} y2={0} stroke="black" />
        <text x={15} y={5} style={{textAnchor: "start", "font-size": "10px"}}>{d.toLocaleString()}</text>
    </g>)}
        <text x={50} y={size_inside_plot/2} text-anchor={"left"} font-size={"10px"}>Sample Size</text>
    </>

}


const SubPlot = ({data, x, y, models, power, confidence, highlightState, setHighlightState}) => {
    const size = x.range()[1] - x.range()[0];
    const rectRef = createRef();

    const handleClick = (ev) => {
        const rect = rectRef.current; // initially used ev.target, but this fails for clicks on circles rather than rect
        const scaling = rect.getBoundingClientRect().width / (size_inside_plot);

        const rawX = (ev.clientX - rect.getBoundingClientRect().left) / scaling;
        const treatmentEffect = x.invert(rawX);

        const allTreatments = data.map(d => d.treatment);
        const treatments = allTreatments.filter((d, i) => allTreatments.indexOf(d) === i);

        const errors = treatments.map(t => Math.abs(t - treatmentEffect));
        const i = errors.indexOf(min(errors));

        setHighlightState({i, treatment: treatments[i], xPos: x(treatments[i]), power, confidence});
    }


    return <>
        <rect x={0} width={size} y={0} height={size} fill={"lightgrey"} opacity={0.2} onclick={handleClick} ref={rectRef} />

        {highlightState !== null && highlightState.power === power && highlightState.confidence === confidence &&
        <line x1={x(highlightState.treatment)} x2={x(highlightState.treatment)} y1={0} y2={size_inside_plot} stroke={"black"}
              style={{"stroke-dasharray": "2,2"}} />}

        {models.map((model) => {
            const lineGen = line()
                .x(d => x(d.treatment))
                .y(d => y(d[model]));

            return <>
                {data.map(d =>
                    <circle cx={x(d.treatment)} cy={y(d[model])} r={2} fill={modelStyle[model].fill} stroke={modelStyle[model].color} onclick={(ev) => handleClick(ev, 4)}>
                        <title>For confidence {confidence}, power {power}, model {model} and treatment
                            effect {d.treatment}, required sample size is {d[model]}</title>
                    </circle>
                )}
                <path d={lineGen(data)} fill="none" stroke={modelStyle[model].color} stroke-width={"1px"} onclick={handleClick} />
            </>
        })
        }
    </>;
}

const ResultList = ({results, highlightState, powers, confidences, treatments, setHighlightState, mortalityRate}) => {
    const data = highlightState ? results.filter(d => d.power === highlightState.power && d.confidence === highlightState.confidence && d.treatment === highlightState.treatment)[0] : [];

    return <div id="resultsTable">
        <h2>Calculated sample size</h2>
        <p>with power{' '}
            <select value={highlightState.power}
                    onChange={(ev) => setHighlightState({...highlightState, power: +ev.target.value})}>
                {powers.map(p => <option key={p} value={p}>{p}</option>)}
            </select>

            {' '}, confidence{' '}
            <select value={highlightState.confidence}
                    onChange={(ev) => setHighlightState({...highlightState, confidence: +ev.target.value})}>
                {confidences.map(p => <option key={p} value={p}>{p}</option>)}
            </select>

            {' '}, and treatment effect{' '}
            <select value={highlightState.treatment}
                    onChange={(ev) => setHighlightState({...highlightState, treatment: +ev.target.value})}>
                {treatments.map(p => <option key={p} value={p}>{p}</option>)}
            </select>

        </p>

        <table>
            {models.map((model, i) => <tr>
                <td style={{color: modelStyle[model].fill, "font-size": "2em", "text-shadow": `-1px -1px 0 ${modelStyle[model].color}, 1px -1px 0 ${modelStyle[model].color}, -1px 1px 0 ${modelStyle[model].color}, 1px 1px 0 ${modelStyle[model].color}` }}>•</td>
                <td>{modelStyle[model].label}</td>
                <td style={{"text-align": "right"}}><b>{data[model].toLocaleString()}</b></td>
            </tr>)}
        </table>

        <p>For patients in this population, the mean mortality rate is {mortalityRate}.</p>
    </div>
}

export {ResultsPlot};

