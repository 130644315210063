import './style';
import {useState, useEffect} from 'preact/hooks';

import {ResultsPlot} from './Plot'

// TODO
// - show values for selected params
// - bug in whether button is enabled
// - choice of values for sweep

// prettier


export default function App() {

    const [filter, setFilter] = useState({
        age_lb: 18,
        age_ub: 100,
        frailty_lb: 1,
        frailty_ub: 9,
        resp_None: 1,
        resp_HNFC: 1,
        resp_NIV: 1,
        resp_IMV: 1
    });

    const [results, setResults] = useState(null);

    // TODO: save results corectly
    useEffect(() => {

        if (!results) {
            fetch("/api/sample_size_grid?" + new URLSearchParams(filter))
                .then(response => response.json())
                .then(data => {
                    console.log("Results:", data)
                    setResults(data);

                });
        }
    }, []);


    if (!results) {
        return null;
    }

    const recalculate = (newFilter) => {
        console.log("Recalculating:", newFilter);
        fetch("/api/sample_size_grid?" + new URLSearchParams(newFilter))
            .then(response => response.json())
            .then(data => {
                console.log("Results:", data)
                setResults(data);
            });

        setFilter(newFilter);
    }

    return (
        <>
            <div>
                <h1>SF94 Trial size calculator</h1>
            </div>

            <div style={{"max-width": "600px", "margin-left": "auto", "margin-right": "auto"}}>
                <p>
                    This S/F94 trial size calculator can be used to calculate and compare the required sample size for
                    different outcome measures, using various inclusion criteria. It compares the following outcome measures:
                    S/F94, WHO ordinal scale, whether patients reached and sustained 1 level improvement on the WHO ordinal
                    scale and a protocolised S/F94 sample size, calculated using a predicted improvement when S/F94 is
                    measured in a protocolised way.
                </p>
            </div>

            <div>
                <InclusionCriteria filters={filter} recalculate={recalculate} />
            </div>

            <ResultsPlot results={results.grid} mortalityRate={results.mortality_rate}/>
        </>
    );
}

const InclusionCriteria = ({filters, recalculate}) => {

    const [criteria, setCriteria] = useState(filters);

    const frailty = {
        1: "Very fit",
        2: "Well",
        3: "Managing Well",
        4: "Vulnerable",
        5: "Mildly Frail",
        6: "Moderately Frail",
        7: "Severely Frail",
        8: "Very Severely Frail",
        9: "Terminally Ill"
    };

    const isNumber = val => typeof val === 'number' && isFinite(val);

    const validInputs = isNumber(criteria.age_lb) && isNumber(criteria.age_ub) && criteria.age_lb <= criteria.age_ub &&
        isNumber(criteria.frailty_lb) &&  isNumber(criteria.frailty_ub) && criteria.frailty_lb <= criteria.frailty_ub;
    const filtersChanged = JSON.stringify(criteria) !== JSON.stringify(filters);
    const buttonEnabled = validInputs && filtersChanged;

    return <>
     <form onsubmit={(ev) => ev.preventDefault()} id={"inclusionForm"}>
         <h3>Inclusion Criteria</h3>

      <input type="text" id="age_lb" name="age_lb" value={criteria.age_lb} size={4} onChange={(ev) => setCriteria({...criteria, age_lb: +ev.target.value})} />  ≤ Age in years ≤
         <input type="text" id="age_ub" name="age_ub" value={criteria.age_ub} size={4} onchange={(ev) => setCriteria({...criteria, age_ub: +ev.target.value})} /><br /><br />

      <input type="text" id="frailty_lb" name="frailty_lb" value={criteria.frailty_lb} size={1} onchange={(ev) => setCriteria({...criteria, frailty_lb: +ev.target.value})} />  ≤ Clinical Frailty Score 	≤
         <input type="text" id="frailty_ub" name="frailty_ub" value={criteria.frailty_ub} size={1} onchange={(ev) => setCriteria({...criteria, frailty_ub: +ev.target.value})} /> <br />

      <h3>Include patients who are:</h3>
      <label for="resp_None">Not receiving any respiratory support:</label>
      <input type="checkbox" id="resp_None" name="resp_None" checked={criteria.resp_None === 1} onchange={(ev) => setCriteria({...criteria, resp_None: ev.target.checked ? 1 : 0})} /><br />

      <label for="resp_HNFC">Receiving High Flow Nasal Canula:</label>
      <input type="checkbox" id="resp_HNFC" name="resp_HNFC" checked={criteria.resp_HNFC === 1} onchange={(ev) => setCriteria({...criteria, resp_HNFC: ev.target.checked ? 1 : 0})} /><br />

      <label for="resp_NIV">Receiving Non Invasive Ventilation:</label>
      <input type="checkbox" id="resp_NIV" name="resp_NIV" checked={criteria.resp_NIV === 1} onchange={(ev) => setCriteria({...criteria, resp_NIV: ev.target.checked ? 1 : 0})} /><br />

     <label for="resp_IMV">Receiving Invasive Mechanical Ventilation:</label>
      <input type="checkbox" id="resp_IMV" name="resp_IMV" checked={criteria.resp_IMV === 1} onchange={(ev) => setCriteria({...criteria, resp_IMV: ev.target.checked ? 1 : 0})} /><br />

         {validInputs ? <button onclick={() => recalculate(criteria)} disabled={ !buttonEnabled }>Re-calculate required sample size</button> : <p style={{"font-weight": "bold"}}>Selected inputs are invalid.</p>}
    </form>
    </>
}
